.navbar-scrolled {
	background-color: #ffffff;
	box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
	transition: all 0.3s ease;
      }
      
      .navbar-scrolled .nav-link {
	color: #333333;
      }
      
      .navbar-scrolled .navbar-brand img {
	filter: none;
      

}
