.footer {
    background-color: $dark;
    color: $light;
    
    .footer-link {
      color: rgba($light, 0.7);
      
      &:hover, &:focus {
        color: $primary;
      }
    }
    
    .social-icon {
      color: $secondary;
      
      &:hover, &:focus {
        color: $primary;
      }
    }
  }
  .footer {
    background-color: #343a40;
  }
  
  .hover-primary:hover {
    color: #007bff !important;
    transition: color 0.3s ease;
  }
  
  .footer-links li {
    margin-bottom: 0.5rem;
  }
  
  .form-control::placeholder {
    color: #adb5bd;
  }