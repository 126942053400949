.contact-section {
    background-color: $info;
    color: $light;
    
    .form-control {
      background-color: rgba($light, 0.1);
      border-color: rgba($light, 0.2);
      color: $light;
      
      &:focus {
        background-color: rgba($light, 0.2);
        border-color: $primary;
      }
    }
    
    .btn-primary {
      background-color: $primary;
      border-color: $primary;
      
      &:hover, &:focus, &:active {
        background-color: darken($primary, 10%);
        border-color: darken($primary, 10%);
      }
    }
  }