.testimonials-section {
    background-color: $secondary;
    color: $dark;
    
    .testimonial-card {
      background-color: $light;
      border-radius: 8px;
      padding: 20px;
      box-shadow: 0 4px 6px rgba($dark, 0.1);
      
      .testimonial-text {
        color: $dark;
      }
      
      .testimonial-author {
        color: $primary;
      }
    }
    
    .slick-dots li button:before {
      color: $primary;
    }
  }