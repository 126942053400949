/* Template Name: Nody - Responsive Bootstrap 4 Landing Page Template
   Author: Themesdesign
   Version: 1.2.0
   Created: Jan 2020
   File Description: Main Css file of the template
*/

/**********************
01.General
02.Menu
03.Components
04.Helper
05.Loader
06.Hero
07.About Us
08.services
09.features
10.project
11.testimonial
12.blog
13.contact
14.footer
***********************/
:root {
  --primary-color: #5DB8C0; // Fountain Blue
  --secondary-color: #BBACE3; // Cold Purple
  --accent-color: #8682BB; // Wild Blue Yonder
  --background-color: #34385F; // Pickled Bluewood
  --text-color: #FFFFFF; // White for contrast
}

@import "variables";
@import "general";
@import "menu";
@import "components";
@import "helper";
@import "loader";
@import "hero";
@import "about";
@import "services";
@import "features";
@import "project";
@import "testimonial";
@import "blog";
@import "contact";
@import "footer";
@import 'navbar';
.navbar-scrolled {
  background-color: #ffffff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;

  .nav-link {
    color: #333333;
  }

  .navbar-brand img {
    filter: none;
  }
}
.navbar-custom {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-brand {
  display: flex;
  align-items: center;
}

.navbar-brand img {
  max-height: 60px;
  width: auto;
}

.navbar-nav {
  align-items: center;
}

@media (max-width: 991.98px) {
  .navbar-custom {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }

  .navbar-brand img {
    max-height: 50px;
  }
}